import { H, Section } from "@jfrk/react-heading-levels";
import PropTypes from "prop-types";
import React from "react";

IframeModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modIframeOptions: PropTypes.shape({
      iframeHeight: PropTypes.number,
      iframeUrl: PropTypes.string,
    }),
  }),
};

export default function IframeModule({
  title,
  iframeSettings: { iframeHeight, iframeUrl },
  ...restProps
}) {
  const MaybeSection = title ? Section : React.Fragment;
  return (
    <div className="c-iframe-module" {...restProps}>
      {title && <H className="c-iframe-module__title">{title}</H>}
      <MaybeSection>
        <iframe
          src={iframeUrl}
          height={iframeHeight}
          width="100%"
          frameBorder={0}
        />
      </MaybeSection>
    </div>
  );
}
