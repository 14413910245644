import cx from "classnames";
import React, { useRef } from "react";

import useThemeSettings from "../../hooks/theme";
import Link from "../Link/Link";

import TrelleborgLogo from "./TrelleborgLogo";

import "./Logo.scss";

export default function Logo({
  color = "currentColor",
  className,
  linkTo,
  linkProps: { ...linkRestProps } = {},
  "aria-label": ariaLabel,
  ...restProps
}) {
  let ref = useRef(null);
  const { themeLogo } = useThemeSettings();

  return (
    <div className={cx("logo", className)} ref={ref} {...restProps}>
      <Link
        to={linkTo}
        className="logo__link"
        fallbackElement="span"
        aria-label={ariaLabel}
        {...linkRestProps}
      >
        <span className="u-visually-hidden">Gå till startsidan</span>
        {themeLogo && themeLogo.sourceUrl ? (
          <img src={themeLogo.sourceUrl} alt="" />
        ) : (
          <TrelleborgLogo color={color} />
        )}
      </Link>
    </div>
  );
}
