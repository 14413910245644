import { H, Section } from "@jfrk/react-heading-levels";
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";
import React, { useState } from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";
import Button from "../Button/Button";

import "./JobAdsModule.scss";

export default function JobAdsModule({
  title,
  items: { textAbove, jobCount },
  ...restProps
}) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [openCardId, setOpenCardId] = useState(null);
  const MaybeSection = title ? Section : React.Fragment;
  const { processContent } = useHTMLProcessor();

  const getJobAds = () => {
    try {
      const {
        allJobAds: { nodes },
      } = useStaticQuery(graphql`
        query JobAdsQuery {
          allJobAds {
            nodes {
              _id
              enddate
              external_description
              external_description_competencies
              external_description_conditions
              external_description_tasks
              name
            }
          }
        }
      `);
      return nodes;
    } catch (err) {
      throw new Error(`Cannot get data from allJob field, ${err}`);
    }
  };

  const nodes = getJobAds();

  if (nodes[0]?._id === "-1") {
    return <p>Jobbannonser kan inte visas för tillfället.</p>;
  }

  const handleCardOpen = (id) => {
    if (isCollapsed) {
      setOpenCardId(null);
    }
    setCollapsed(!isCollapsed);
    setOpenCardId(id);
  };

  const handleIcon = (id) => {
    let icon = "arrow-down-1";
    if (!isCollapsed && openCardId === id) icon = "arrow-up-1";
    if (isCollapsed && !openCardId) icon = "arrow-down-1";
    return icon;
  };

  if (jobCount !== -1) {
    nodes.splice(jobCount);
  }

  return (
    <div className="c-disturbances-module" {...restProps}>
      {title && <H className="c-disturbances-module__title">{title}</H>}
      <MaybeSection>
        <div className="container">
          {textAbove && <div className="text">{processContent(textAbove)}</div>}
          {nodes &&
            nodes.map(
              ({
                _id,
                name,
                external_description_tasks,
                external_description_conditions,
                external_description_competencies,
                external_description,
                enddate,
              }) => {
                return (
                  <article className="c-card c-card--spacing article" key={_id}>
                    <div className="c-card__title o-link--internal c-card__title-heading-link aritcleTitle">
                      <h2 className="c-card__title-heading">
                        <a
                          className="c-card__title-heading-link"
                          href={
                            "https://trelleborg.lime-portal.se/assignment/" +
                            _id
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {name}
                        </a>
                      </h2>
                      <div onClick={() => handleCardOpen(_id)}>
                        <Button
                          className="c-box-navigation__title c-box-navigation__title--w-link"
                          iconAfterName={handleIcon(_id)}
                          iconAfter={true}
                          buttonModifier="prominent-link"
                          iconModifier="primary"
                          iconBgColor="var(--color-action)"
                          roundIcon={true}
                          iconAfterSize="24px"
                        />
                      </div>
                    </div>
                    <div className="c-card__body">
                      <div className="sectionText">
                        <p>{external_description}</p>
                      </div>
                      {!isCollapsed && openCardId === _id && (
                        <div>
                          <div className="sectionText">
                            <p className="sectionName">Arbetsuppgifter:</p>
                            {external_description_tasks}
                          </div>
                          <div className="sectionText">
                            <p className="sectionName">Kvalifikationer:</p>
                            {external_description_competencies}
                          </div>
                          <div className="sectionText">
                            <p className="sectionName">Villkor:</p>
                            {external_description_conditions}
                          </div>
                          <div className="sectionText">
                            <p className="sectionName">Sista ansökningsdag:</p>{" "}
                            {moment(enddate).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      )}
                    </div>
                  </article>
                );
              },
            )}
        </div>
      </MaybeSection>
    </div>
  );
}
