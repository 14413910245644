import { H, Section } from "@jfrk/react-heading-levels";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

import "./BathTemperatureModule.scss";

export default function BathTemperatureModule({
  title,
  items: { textAbove, textBelow },
  ...restProps
}) {
  const MaybeSection = title ? Section : React.Fragment;
  const { processContent } = useHTMLProcessor();

  const getBathTemperature = () => {
    try {
      const {
        allBathTemperature: { nodes },
      } = useStaticQuery(graphql`
        query BathTempertureQuery {
          allBathTemperature {
            nodes {
              temperature
              id
            }
          }
        }
      `);
      return nodes;
    } catch (err) {
      throw new Error(`Cannot get data from allBathTemperature field, ${err}`);
    }
  };

  const nodes = getBathTemperature();

  if (!nodes?.length) {
    return <p>Temperaturen för badplatserna kan inte visas för tillfället.</p>;
  }

  const findNeededTemperature = (id) => {
    const device = nodes?.find((item) => item.id === id);
    return device?.temperature?.slice(0, -1);
  };

  return (
    <div className="c-disturbances-module" {...restProps}>
      {title && <H className="c-disturbances-module__title">{title}</H>}
      <MaybeSection>
        <div>
          <div> {processContent(textAbove)}</div>
          {nodes && (
            <div className="temperatureWrapper">
              <div>
                <div className="temperatureLocation">Beddingestrand</div>
                <div>{findNeededTemperature("70b3d580a0111d89")} grader</div>
              </div>
              <div>
                <div className="temperatureLocation">Skåre</div>
                <div>{findNeededTemperature("70b3d580a0111d97")} grader</div>
              </div>
              <div>
                <div className="temperatureLocation">Dalabadet</div>
                <div>{findNeededTemperature("70b3d580a0111d99")} grader</div>
              </div>
            </div>
          )}
          <div> {processContent(textBelow)}</div>
        </div>
      </MaybeSection>
    </div>
  );
}
