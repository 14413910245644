import Axios from "axios";
import React, { useState, useEffect } from "react";

import ExpandableList from "../ExpandableList/ExpandableList";
import SectionHeader from "../SectionHeader/SectionHeader";

const axios = Axios.create({
  baseURL: process.env.GATSBY_API_URL,
});

export default function BusStatusModule({
  title,
  description,
  type = `status`,
  ...restProps
}) {
  const [data, setData] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        let request = await axios({
          method: "get",
          url: `/bus/${type}`,
        });

        let returnData = [];
        if (request?.data?.data?.length) {
          returnData = request.data.data.map((item) => {
            let itemTitle = item.Rubrik;
            if (item.Startdatum && item.Slutdatum) {
              itemTitle += ` (${item.Startdatum} - ${item.Slutdatum})`;
            }

            return {
              title: itemTitle,
              content: item.Meddelande,
            };
          });
        }
        setData(returnData);
      } catch (err) {
        // console.log(err);
      }
    }
    fetchData();
  }, []);

  if (!data?.length) {
    return (
      <>
        {title && (
          <SectionHeader
            title={title}
            description={description}
            sectionHeader={{
              title: title,
              withBorder: true,
            }}
            noMarginBottom={true}
            {...restProps}
          />
        )}
      </>
    );
  }

  return (
    <ExpandableList
      sectionHeader={{
        content: {
          title: title,
          withBorder: true,
        },
        noMarginBottom: true,
      }}
      items={data.map((item) => ({
        ...item,
      }))}
      {...restProps}
    />
  );
}
