import { H, Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
// import Img from "gatsby-image";
import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";
import Icon from "../Icon/Icon";
import { FluidImage } from "../Image";

const SOCIAL_MEDIA_NAMES = {
  facebook: "Facebook",
  linkedin: "LinkedIn",
  twitter: "Twitter",
  instagram: "Instagram",
};

export default function ContactCardModule({
  title,
  mode,
  isCompact,
  items,
  // ...restProps
}) {
  const { processContent } = useHTMLProcessor();
  const MaybeSection = title ? Section : React.Fragment;
  const isRowMode = mode == "row" ? true : false;
  return (
    <div className="c-contact-card">
      {title && <H className="c-contact-card__title">{title}</H>}

      <div className={cx("c-card-group", isRowMode && "c-card-group--fifth")}>
        <MaybeSection>
          {items.map((item, index) => {
            return (
              <address
                className={cx(
                  "c-card",
                  !isRowMode && "c-card--spacing c-card--style-default",
                  mode && `c-card--${mode}`,
                )}
                itemScope=""
                itemType="http://schema.org/Person"
                key={index}
              >
                {item.image && (
                  <div className="c-card__avatar">
                    <FluidImage
                      className="c-card__avatar-image"
                      src={item.image.src}
                      srcSet={item.image.srcSet || item.image.src}
                      width={item.image.width}
                      height={item.image.height}
                      base64={item.image.base64}
                      aspectRatio={1}
                      alt={
                        item.lastName
                          ? item.firstName + " " + item.lastName
                          : item.firstName
                      }
                    />
                  </div>
                )}
                <div className="c-card__title">
                  <H className="c-card__title-heading" itemProp="name">
                    {item.lastName
                      ? `${item.firstName} ${item.lastName}`
                      : item.firstName}
                  </H>
                  {item.workTitle && (
                    <p className="c-card__job-title" itemProp="jobTitle">
                      {item.workTitle}
                    </p>
                  )}
                  {item.administrationUnit && (
                    <p className="c-card__job-department" itemProp="jobTitle">
                      {item.administrationUnit}
                    </p>
                  )}
                </div>
                <div
                  className={cx(
                    "c-card__body",
                    isCompact && "c-card__body--dense",
                  )}
                >
                  <div className="c-card__contact-details">
                    {item.email && (
                      <div>
                        <span className="u-visually-hidden">E-post</span>
                        <a
                          className="c-card__link"
                          href={`mailto:${item.email}`}
                          itemProp="email"
                        >
                          <Icon name="email-bold" className="u-m-r--200" />
                          <span
                            className={cx(isCompact && "u-visually-hidden")}
                          >
                            {item.email}
                          </span>
                        </a>
                      </div>
                    )}
                    {item.phoneNumbers &&
                      item.phoneNumbers.map(({ type, number }, index) => {
                        if (!number) {
                          return null;
                        }
                        let label =
                          type == "phone" ? "Telefonnummer" : "Mobilnummer";
                        return (
                          <div key={index}>
                            <span className="u-visually-hidden">{label}</span>
                            <div itemProp="telephone">
                              <Icon
                                name={`${type}-bold`}
                                className="u-m-r--200"
                              />
                              <span
                                className={cx(isCompact && "u-visually-hidden")}
                              >
                                {number}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    {item.socialMedia &&
                      item.socialMedia.map(({ media, url }, index) => {
                        return (
                          url && (
                            <div key={index}>
                              <a
                                className="c-card__link"
                                href={url}
                                key={index}
                              >
                                <Icon
                                  name={`${media}-bold`}
                                  className="u-m-r--200"
                                />
                                <span
                                  className={cx(
                                    isCompact && "u-visually-hidden",
                                  )}
                                >
                                  {SOCIAL_MEDIA_NAMES[media]}
                                </span>
                              </a>
                            </div>
                          )
                        );
                      })}
                  </div>
                  {item.address && (
                    <div className="c-card__address">
                      <H className="c-heading heading--size-4">Adress</H>
                      {processContent(item.address)}
                    </div>
                  )}
                  {item.visitingAddress && (
                    <div className="c-card__address">
                      <H className="c-heading heading--size-4">Besöksadress</H>
                      {processContent(item.visitingAddress)}
                    </div>
                  )}
                  {item.openingHours && (
                    <div className="c-article">
                      <H className="c-heading heading--size-4">Öppningstider</H>
                      {processContent(item.openingHours)}
                    </div>
                  )}
                  {item.other && (
                    <div className="c-article">
                      {processContent(item.other)}
                    </div>
                  )}
                </div>
              </address>
            );
          })}
        </MaybeSection>
      </div>
    </div>
  );
}
